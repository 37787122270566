window.formValidation = function formValidation(formData) {
  try {
    console.log(formData);
    const data = typeof formData === 'string' ? JSON.parse(formData) : formData;

    const formId = data.form_id;
    const errorMessages = data.names;
    const formErrorMessage = data.error;

    const defaultOptions = {
      errorTextClasses: 'text-red-400',
      errorTextClassesCheckRadio: 'text-red-400 absolute bottom-0 left-0',
      errorTextClassesFile: 'text-red-400',
      errorTextClassesSelect: 'text-red-400',
      inputErrorClasses: '!border-red-400',
      selectErrorClasses: '!border-red-400',
      errorTextClassesForm: 'text-red-400',
      defaultErrorMessage: 'This field is required',
      errorClearDelay: 500,
      transitionDelay: 300,
    };

    const finalOptions = { ...defaultOptions };
    const errors = {};

    const form = document.getElementById(formId);
    if (!form) {
      throw new Error(`Form with ID "${formId}" not found.`);
    }

    if (formErrorMessage) {
      let formErrorElement = form.querySelector('.form-error-text');
      if (formErrorElement) {
        formErrorElement.remove();
      }

      formErrorElement = document.createElement('div');
      formErrorElement.className = `form-error-text ${finalOptions.errorTextClassesForm}`;
      formErrorElement.textContent = formErrorMessage;

      form.classList.add('error');
      form.appendChild(formErrorElement);
      /*form.insertBefore(formErrorElement, form.firstChild);*/


      if(form.querySelector('input, textarea')) {
        const inputs = form.querySelectorAll('input, textarea');
        inputs.forEach(input => {
          input.addEventListener('click', () => {
            form.classList.remove('error');
          })

          input.addEventListener('change', () => {
            form.classList.remove('error');
          })
        })
      }
    }

    if (errorMessages && (typeof errorMessages !== 'object' || Array.isArray(errorMessages))) {
      throw new Error('Invalid error format. Expected an object.');
    }

    if (errorMessages) {
      Object.keys(errorMessages).forEach((fieldName) => {
        const inputField = form.elements[fieldName];
        if (!inputField) {
          console.warn(`Input field "${fieldName}" not found in form.`);
          return;
        }

        const fieldValue = inputField.value.trim();
        const parentElement = inputField.parentElement;
        const isCheckboxOrRadio = inputField.type === 'checkbox' || inputField.type === 'radio';
        const isFile = inputField.name === 'cv_field';
        const isSelect = inputField.tagName.toLowerCase() === 'select';

        const errorMessage = errorMessages[fieldName]?.[0] || finalOptions.defaultErrorMessage;

        const errorTextClass = isFile
            ? finalOptions.errorTextClassesFile
            : isCheckboxOrRadio
                ? finalOptions.errorTextClassesCheckRadio
                : isSelect
                    ? finalOptions.errorTextClassesSelect
                    : finalOptions.errorTextClasses;

        let errorTextElement = isSelect
            ? parentElement.parentElement.parentElement.querySelector('.error-text')
            : parentElement.querySelector('.error-text');

        if (!isCheckboxOrRadio && !isFile && !isSelect) {
          errors[fieldName] = errorMessage;

          inputField.classList.add('error', ...finalOptions.inputErrorClasses.split(' '));

          if (!errorTextElement) {
            errorTextElement = document.createElement('span');
            errorTextElement.className = `error-text ${errorTextClass}`;
            parentElement.appendChild(errorTextElement);
          }
          errorTextElement.textContent = errorMessage;
        } else if (!isCheckboxOrRadio && !isFile && !isSelect) {
          inputField.classList.remove('error', ...finalOptions.inputErrorClasses.split(' '));

          if (errorTextElement) {
            setTimeout(() => {
              parentElement.removeChild(errorTextElement);
            }, finalOptions.transitionDelay + finalOptions.errorClearDelay);
          }
        }

        else if (isCheckboxOrRadio || isFile || isSelect) {
          errors[fieldName] = errorMessage;

          inputField.classList.add('error');

          if (isCheckboxOrRadio || isFile) {
            parentElement.classList.add('error');
          } else if (isSelect) {
            const selectParent = parentElement.parentElement;
            selectParent.classList.add('error', ...finalOptions.selectErrorClasses.split(' '));
          }

          if (!errorTextElement) {
            errorTextElement = document.createElement('span');
            errorTextElement.className = `error-text ${errorTextClass}`;

            if (isFile) {
              parentElement.insertAdjacentElement('afterend', errorTextElement);
            } else if (isSelect) {
              const selectParent = parentElement.parentElement;
              selectParent.insertAdjacentElement('afterend', errorTextElement);
            } else {
              parentElement.appendChild(errorTextElement);
            }
          }
          errorTextElement.textContent = errorMessage;
        }

        inputField.addEventListener('click', () => {
          setTimeout(() => {
            inputField.classList.remove('error', ...finalOptions.inputErrorClasses.split(' '));
            form.classList.remove('error');
            if (errorTextElement) {
              setTimeout(() => {
                errorTextElement.remove();
              }, finalOptions.transitionDelay * 2);
            }
          }, finalOptions.errorClearDelay);
        });

        if(isFile) {
          inputField.parentElement.addEventListener('click', () => {
            setTimeout(() => {
              inputField.classList.remove('error', ...finalOptions.inputErrorClasses.split(' '));
              form.classList.remove('error');
              if (errorTextElement) {
                setTimeout(() => {
                  errorTextElement.remove();
                }, finalOptions.transitionDelay * 2);
              }
            }, finalOptions.errorClearDelay);
          })
        }
      });
    }

    function updateAllSimpleBars() {
      document.querySelectorAll('.simplebar-content').forEach((content) => {
        const simpleBarInstance = SimpleBar.instances.get(content.closest('[data-simplebar]'));
        if (simpleBarInstance) {
          simpleBarInstance.recalculate();
        }
      });
    }
    updateAllSimpleBars();

    return errors;
  } catch (error) {
    console.error('Error parsing JSON data:', error.message);
    return {};
  }
};
