import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

window.videoSwiperInit = function videoSwiperInit() {
  let videosSwiperWr = document.querySelectorAll('.videos-swiper-wr');
  videosSwiperWr.forEach((el) => {
    if (el) {
      let swiperEl = el.querySelector('.swiper');
      let parentEl = el.parentElement;
      let nextEl = parentEl.querySelector('.swiper-button-next');
      let prevEl = parentEl.querySelector('.swiper-button-prev');
      let slidesCount = el.querySelectorAll('.swiper-slide').length;
      let wrapper = swiperEl.querySelector('.swiper-wrapper');
      let swiper = new Swiper(swiperEl, {
        modules: [Autoplay, Navigation],
        observer: true, //needs to calculate swiper size
        observeParents: true, //needs to calculate swiper size
        spaceBetween: 20,
        slidesPerView: 1,
        threshold: 10,
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
        breakpoints: {
          640: {
            spaceBetween: 20,
            slidesPerView: 1,
          },
          1024: {
            spaceBetween: 30,
            slidesPerView: 2,
          },
          1440: {
            slidesPerView: 2,
          }
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: window.innerWidth >= 640,
        },
      });
      swiperObserver(swiper);
    }
  });
}

videoSwiperInit();

window.videoSecondSwiperInit = function videoSecondSwiperInit() {
  let videosSecondSwiperWr = document.querySelectorAll('.videos-second-swiper-wr');

  videosSecondSwiperWr.forEach((el) => {
    if (el) {
      let swiperEl = el.querySelector('.swiper');
      let parentEl = el.parentElement;
      let nextEl = parentEl.querySelector('.swiper-button-next');
      let prevEl = parentEl.querySelector('.swiper-button-prev');
      let slidesCount = el.querySelectorAll('.swiper-slide').length;
      let wrapper = swiperEl.querySelector('.swiper-wrapper');
      let swiper = new Swiper(swiperEl, {
        modules: [Autoplay, Navigation],
        observer: true, //needs to calculate swiper size
        observeParents: true, //needs to calculate swiper size
        spaceBetween: 20,
        slidesPerView: 1,
        threshold: 10,
        navigation: {
          nextEl: nextEl,
          prevEl: prevEl,
        },
        breakpoints: {
          640: {
            spaceBetween: 20,
            slidesPerView: 1,
          },
          769: {
            spaceBetween: 20,
            slidesPerView: 2,
          },
          1024: {
            spaceBetween: 30,
            slidesPerView: 3,
          },
          1440: {
            slidesPerView: 3,
          }
        },
        autoplay: {
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: window.innerWidth >= 640,
        },
      });
      swiperObserver(swiper);
    }
  });
}

videoSecondSwiperInit();