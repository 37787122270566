import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { swiperOnResize } from '../utils/swiperOnResize.utils.js';

let galleryMicrositeSwiperWr = document.querySelectorAll('.gallery-microsite-swiper-wr');
galleryMicrositeSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');

    swiperOnResize('(max-width: 1024px)', swiperEl, {
      modules: [Autoplay, Navigation],
      spaceBetween: 20,
      slidesPerView: 1,
      loop: slidesCount > 4,
      loopedSlides: slidesCount > 4 ? slidesCount : false,
      breakpoints: {
        640: {
          spaceBetween: 20,
          slidesPerView: 2,
        },
        768: {
          spaceBetween: 20,
          slidesPerView: 2.5,
        },
        1023: {
          spaceBetween: 20,
          slidesPerView: 3,
        },
      },
      navigation: {
        prevEl: prevEl,
        nextEl: nextEl,
      },
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
    });
  }
});
