import Swiper, {
  Autoplay,
  Controller,
  EffectCreative,
  Mousewheel,
  Navigation,
} from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

function initCirceFunc() {
  const metaCircleBlock = document.getElementsByClassName('meta-circle')[0];
  if (metaCircleBlock) {
    const wrapArr = Array.from(
        metaCircleBlock.querySelectorAll('.meta-circle__wrap')
    );
    const itemArr = Array.from(
        metaCircleBlock.querySelectorAll('.meta-circle__item')
    );
    const scrollZone = document.querySelector('.meta-circle-scroll-zone');
    const metaCircleSection = document.getElementById('meta-circle-sec');

    let activeIndex = 0;
    let previousSlideIndex = 0;
    let clickedIndex = 0;
    let activeIndexClass = 0;
    let previousClickedIndex = 0;

    wrapArr[activeIndexClass].classList.add('active');

    if (scrollZone) {
      /*scrollZone.addEventListener('wheel', function (event) {
        event.preventDefault();
        if (event.deltaY > 0) {
          rotateAnimation();
          /!*updateActiveClassBackward();*!/
          swiper.slideTo(activeIndex);
          /!*console.log('down');*!/
        } else {
          rotateAnimationBackward();
          /!*updateActiveClass();*!/
          swiper.slideTo(activeIndex);
          /!*console.log('up');*!/
        }
      });*/
    }

    function rotateAnimation() {
      activeIndex = (activeIndex + 1) % itemArr.length;
      wrapArr.forEach((elem, index) => {
        elem.style.transform = `rotate(${
            (activeIndex + index) * step + defaultTransform
        }deg)`;
        elem.children[0].style.transform = `rotate(${
            -1 * ((activeIndex + index) * step + defaultTransform)
        }deg) translateY(-50%)`;
      });
    }

    /*setInterval(function () {
      rotateAnimation();
    }, 1000);*/

    function rotateAnimationBackward() {
      activeIndex = (activeIndex - 1 + itemArr.length) % itemArr.length;
      wrapArr.forEach((elem, index) => {
        elem.style.transform = `rotate(${
            (activeIndex + index) * step + defaultTransform
        }deg)`;
        elem.children[0].style.transform = `rotate(${
            -1 * ((activeIndex + index) * step + defaultTransform)
        }deg) translateY(-50%)`;
      });
    }

    function updateActiveClass() {
      wrapArr[activeIndexClass].classList.remove('active');

      activeIndexClass = (activeIndexClass + 1) % wrapArr.length;

      wrapArr[activeIndexClass].classList.add('active');
    }

    function updateActiveClassBackward() {
      wrapArr[activeIndexClass].classList.remove('active');

      activeIndexClass = (activeIndexClass - 1 + wrapArr.length) % wrapArr.length;

      wrapArr[activeIndexClass].classList.add('active');
    }

    /*function updateActiveClassOnClick(idxSlide, idxItemClick) {
      activeIndex = idxSlide;
      /!*rotateAnimation();*!/
      swiper.slideTo(idxItemClick);
      setTimeout(function () {
        for (let i = 0; i < itemArr.length; i++) {
          wrapArr[i].classList.remove('active');
        }
        wrapArr[idxItemClick].classList.add('active');
      }, 1000);
    }

    itemArr.forEach((item, i) => {
      item.addEventListener('click', () => {
        console.log(item.dataset.idx);
        console.log(i, activeIndexClass);
        updateActiveClassOnClick(i, Number(item.dataset.idx));
      });
    });*/

    const step = 360 / wrapArr.length;

    let additionalDeg = window.innerWidth <= 851 ? 0 : 0;

    let defaultTransform =
        +metaCircleBlock.getAttribute('data-default-transform').replace('deg', '') +
        additionalDeg;

    window.addEventListener('resize', () => {
      additionalDeg = window.innerWidth <= 851 ? 0 : 0;
      defaultTransform =
          +metaCircleBlock
              .getAttribute('data-default-transform')
              .replace('deg', '') + additionalDeg;
    });

    wrapArr.forEach((elem, index) => {
      elem.style.transform = `rotate(${index * step + defaultTransform}deg)`;
      elem.children[0].style.transform = `rotate(${
          -1 * (index * step + defaultTransform)
      }deg) translateY(-50%)`;
    });

    let circleSwiperWr = document.querySelector('.circle-swiper-wr');
    let swiperEl = circleSwiperWr.querySelector('.swiper');
    let nextEl = circleSwiperWr.querySelector('.swiper-button-next');
    let prevEl = circleSwiperWr.querySelector('.swiper-button-prev');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation, Controller],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 20,
      slidesPerView: 1,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      speed: 500,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
      on: {
        slideChange: function () {
          const currentIndex = swiper.activeIndex;
          let direction = currentIndex > previousSlideIndex ? 'down' : 'up';
          console.log(`Swiper slide changed: ${direction}`);
          console.log(currentIndex, previousSlideIndex);

          const difference = Math.abs(currentIndex - previousSlideIndex);

          if (direction === 'down') {
            if (difference === 1) {
              updateActiveClassBackward();
            } else if (difference === 2) {
              updateActiveClassBackward();
              updateActiveClassBackward();
            } else if (difference === 3) {
              updateActiveClassBackward();
              updateActiveClassBackward();
              updateActiveClassBackward();
            }
          } else {
            if (difference === 1) {
              updateActiveClass();
            } else if (difference === 2) {
              updateActiveClass();
              updateActiveClass();
            } else if (difference === 3) {
              updateActiveClass();
              updateActiveClass();
              updateActiveClass();
            }
          }

          previousSlideIndex = currentIndex;

          rotateToSlide(swiper.activeIndex);
        },
      },
    });

    /*itemArr.forEach((item, i) => {
      item.addEventListener('click', () => {
        console.log(i, i === 1)
        if (i === 3) {
          swiper.slideTo(1);
        } else if(i === 2) {
          swiper.slideTo(2);
        } else if(i === 1) {
          swiper.slideTo(3);
        } else {
          swiper.slideTo(i);
        }
      });
    });*/

    function rotateToSlide(index) {
      activeIndex = index % wrapArr.length;
      wrapArr.forEach((elem, idx) => {
        elem.style.transform = `rotate(${
            (activeIndex + idx) * step + defaultTransform
        }deg)`;
        elem.children[0].style.transform = `rotate(${
            -1 * ((activeIndex + idx) * step + defaultTransform)
        }deg) translateY(-50%)`;
      });
    }
  }
}

createVisibilityObserver('meta-circle', initCirceFunc);

