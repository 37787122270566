import './utils/vh.utils.js';
import './utils/meta.utils.js';
import './utils/swiperMarquee.utils.js';
// import './utils/animate-on-view.utils'

import './libs/countUp.lib.js';
import './libs/aos.lib.js';

// import './components/uploadFile.component.js';
import './components/inputs.component.js';
import './components/validation.component.js';
import './components/player.component.js';
import './components/circle.component.js';
import './components/tabs.component.js';
import './components/jobs-search.component.js';
import './components/sticky-position.component.js';

import './components/sw-testimonials.component.js';
import './components/sw-identity.component.js';
import './components/sw-benefits.component.js';
import './components/sw-services.component.js';
import './components/sw-jobs.component.js';
import './components/sw-employers.component.js';
import './components/sw-partners.component.js';
import './components/sw-stats.component.js';
import './components/sw-gallery.component.js';
import './components/sw-points.component.js';
import './components/sw-values.component.js';
import './components/sw-gallery-microsite.component.js';
import './components/sw-blog.component.js';
import './components/sw-videos.component.js';
import './components/sw-books.component.js';
import './components/sw-podcasts.component.js';

function activeOnView(animElem) {
  const elements = document.querySelectorAll(animElem);

  elements.forEach((element) => {
    if (
      element.getBoundingClientRect().top < innerHeight / 2 &&
      element.getBoundingClientRect().bottom > innerHeight / 2 &&
      !element.classList.contains('is-viewed')
    ) {
      element.classList.add('is-viewed');
    }
  });
}

activeOnView('.is-anim');
window.addEventListener('scroll', () => activeOnView('.is-anim'));

const header = document.getElementsByClassName(`header`)[0];
const burger = document.getElementsByClassName(`header__burger`)[0];
const burgerText = document.getElementsByClassName(`text-burger-menu`)[0];

let vh = window.innerHeight * 0.01;
document.documentElement.style.setProperty('--vh', `${vh}px`);

window.onresize = function () {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

let prevScrollPos = window.pageYOffset;

if (window.scrollY !== 0) {
  header.classList.add('scrolled');
}

window.addEventListener('scroll', () => {
  const currentScrollPos = window.pageYOffset;
  const headerHeight = header.scrollHeight;

  if (window.scrollY > 0) {
    if (prevScrollPos > currentScrollPos) {
      header.style.top = 0;
      header.classList.add('scrolled');
    } else {
      header.style.top = `-${headerHeight}px`;
    }
  }

  if (burger.classList.contains('active')) {
    header.classList.remove('scrolled');
  } else {
    header.classList.add('scrolled');
  }

  if (window.scrollY === 0) {
    header.classList.remove('scrolled');
  }

  prevScrollPos = currentScrollPos;
});

// Header scroll
// const scrollContainer = () => {
//   return document.documentElement || document.body;
// };
//
// document.addEventListener("scroll", () => {
//   if (scrollContainer().scrollTop > 0) {
//     header.classList.add("scrolled");
//   } else if (scrollContainer().scrollTop == 0) {
//     header.classList.remove("scrolled");
//   }
// });

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
  document.documentElement
).getPropertyValue('--mobile-menu-start-point');
let isMobileMenuEnable =
  $(window).outerWidth() <= mobileMenuStartPoint || $('.header-mobile').length;

$('.dropdown-toggle').click(function (e) {
  if (isMobileMenuEnable) {
    //close all opened sub menu
    $('.menu-item.dropdown.active .dropdown-menu').slideUp({
      complete: function () {
        $(this).closest('.dropdown').removeClass('active');
      },
    });

    //open current submenu
    $(this).closest('.menu-item.dropdown').toggleClass('active');

    if ($(this).closest('.menu-item.dropdown').hasClass('active')) {
      e.preventDefault();
      $(this).next('.dropdown-menu').slideDown();
    }
  }
});

// toggle menu handler
function menuToggle() {
  $('.menu-toggle').toggleClass('active');
  $('.navbar').toggleClass('active');
  $('.header-close-wrapper').toggleClass('active');

  if (window.scrollY !== 0) {
    $('.header').toggleClass('scrolled');
  }

  burger.classList.contains('active')
    ? (burgerText.textContent = 'Close')
    : (burgerText.textContent = 'Menu');

  // LockScroll when burger open and enable when closed and enable scroll on menu
  scrollLock.getScrollState()
    ? scrollLock.disablePageScroll(document.querySelector('.navbar .cont'))
    : scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
  isMobileMenuEnable =
    $(window).outerWidth() <= mobileMenuStartPoint ||
    $('.mobile-header').length;
  if (!isMobileMenuEnable) {
    $('.dropdown-menu').css('display', '');
    $('.header-close-wrapper').removeClass('active');
    $('.menu-item.active').removeClass('active');
    $('.navbar-nav').removeClass('active');
    $('.menu-toggle').removeClass('active');
    // LockScroll when burger open and enable when closed
    scrollLock.enablePageScroll();
  }
}
$(window).on('resize orientationchange', updateMenu);
// end of toggle menu handler

$('.menu-toggle').click(menuToggle); //menu toggles
$('.header-close-wrapper').click(menuToggle); //menu toggles

// Приклад приєднання lottie на проекті (BOLD треба приєднювати тепер просто .gif)
// lottie.loadAnimation({
//   container: document.getElementById(``),
//   renderer: "svg",
//   loop: true,
//   autoplay: true,
//   path: "./js/lottie/name.json",
// });
// test

// Count up Section
/*const statsSection = document.querySelector(`.stats`);
if (statsSection) {
  const countsArr = [...document.getElementsByClassName(`stats-count-up`)];
  const defaultOptions = {
    separator: '',
    enableScrollSpy: true,
    scrollSpyRunOnce: true,
  };

  countsArr.forEach((elem) => {
    let html = elem.innerHTML;
    const number = html.match('\\d+')[0];
    let prefix = '';

    if (html.indexOf(number) > 0) {
      prefix = html.slice(0, html.indexOf(number));
      html = html.replace(prefix, '');
    }

    const suffix = html.replace(number, '');

    let optionsFromDataAttr = $(elem).data();
    for (const key in optionsFromDataAttr) {
      if (optionsFromDataAttr[key] === '') {
        optionsFromDataAttr[key] = true;
      }
    }

    optionsFromDataAttr.prefix = prefix;
    optionsFromDataAttr.suffix = suffix;

    new CountUp(
      elem.id,
      number,
      Object.assign(Object.assign({}, defaultOptions), optionsFromDataAttr)
    );
  });
}*/

// Talent Solutions Tabs
const tabsSections = document.querySelectorAll('.talent-solutions');

if (tabsSections.length) {
  tabsSections.forEach((tabsSection, index) => {
    const tabs = tabsSection.querySelectorAll('.tabs-btns__item'), // Tabs list
          tabsParent = tabsSection.querySelector('.tabs-btns'), // Parent of tabs list
          tabsContent = tabsSection.querySelectorAll('.tabs-images__item'); // Content List
    console.log(tabsSection);
    console.log(tabs);
    
    // Hide items Function
    function hideItems() {
      tabsContent.forEach((item) => {
        item.classList.remove('active');
      });

      tabs.forEach((item) => {
        item.classList.remove('active');

        const tabsDesc = item.querySelector('.tabs-btns__item-content');
        tabsDesc.style.height = 0;
      });
    }

    // Show items Function
    function showItems(i = 0) {
      tabsContent[i].classList.add('active');
      tabs[i].classList.add('active');

      const tabsDesc = tabs[i].querySelector('.tabs-btns__item-content');
      tabsDesc.style.height = tabsDesc.scrollHeight + 'px';
    }

    hideItems();

    setTimeout(function () {
      showItems();
    }, 300);

    // Event delegation
    tabsParent.addEventListener('click', (event) => {
      const target = event.target;

      if (target && target.classList.contains('tabs-btns__item')) {
        tabs.forEach((item, i) => {
          if (target == item) {
            hideItems();
            showItems(i);
          }
        });
      }
    });
  })
}

/*   Hover buttons */

window.followOnHover = function followOnHover(hovered, following) {
  if(!hovered.classList.contains('is-init')) {
    hovered.classList.add('is-init');

    const onMouseMove = (event) => {
      let x = event.offsetX;
      let y = event.offsetY;

      if (event.target.classList.contains('btn')) {
        following.style.transform = `translate(calc(${x}px - 50%), calc(${y}px - 50%)) scale(0.5)`;
        following.style.borderWidth = '4px';
      }
    };

    const removeMouseMove = () => {
      following.style.transform = `translate(-50%, -50%) scale(1)`;
      following.style.borderWidth = '';
    };

    const onResize = () => {
      if (window.innerWidth < 1025) {
        hovered.removeEventListener('mousemove', onMouseMove);
        hovered.removeEventListener('mouseleave', removeMouseMove);
      } else {
        hovered.addEventListener('mousemove', onMouseMove);
        hovered.addEventListener('mouseleave', removeMouseMove);
      }
    };

    window.addEventListener('resize', onResize);
    onResize();

    hovered.addEventListener('mousemove', onMouseMove);
    hovered.addEventListener('mouseleave', removeMouseMove);
  }
}

window.initBtnFunc = function initBtnFunc() {
  const buttons = document.querySelectorAll('.btn');
  if (buttons.length) {
    buttons.forEach((btn) => {
      let circle = btn.querySelector('.circle');

      followOnHover(btn, circle);
    });
  }
}
initBtnFunc();

if(document.querySelector('.target-blank')) {
  document.querySelectorAll('.target-blank').forEach(element => {
    if (!element.hasAttribute('target') || element.getAttribute('target') !== '_blank') {
      element.setAttribute('target', '_blank');
    }
  });
}


// Testimonials item-top HEIGHT Generator

document.querySelectorAll(".testimonials-section__slider").forEach(slider => {
  let maxHeight = 0;
  const items = slider.querySelectorAll(".testimonials-section__item-top");

  items.forEach(item => {
    const height = item.offsetHeight;
    if (height > maxHeight) {
      maxHeight = height;
    }
  });

  items.forEach(item => {
    item.style.minHeight = maxHeight + "px";
  });
});
