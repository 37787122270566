const jobsSearchComponent = document.querySelector('.jobs-search-section');
if (jobsSearchComponent) {
  const filtersBtn = jobsSearchComponent.querySelector(
    '.jobs-search-section__filters-btn'
  );
  const container = jobsSearchComponent.querySelector(
    '.jobs-search-section__container'
  );
  const content = jobsSearchComponent.querySelector(
    '.jobs-search-section__content'
  );

  filtersBtn.addEventListener('click', () => {
    if (container.classList.contains('active')) {
      container.classList.remove('active');
      filtersBtn.textContent = 'Open Filters';
    } else {
      container.classList.add('active');
      filtersBtn.textContent = 'Close Filters';
    }
  });

  content.addEventListener('click', () => {
    if (container.classList.contains('active')) {
      container.classList.remove('active');
      filtersBtn.textContent = 'Open Filters';
    }
  });
}
