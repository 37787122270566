import { CountUp } from 'countup.js';

/**
 * CountUp Metamorfosi function
 * (just add .count-up class anywhere and setup unique option in data attributes)
 **/

const counts = Array.from(document.getElementsByClassName('count-up'));
if (counts) {
  const defaultOptions = {
    separator: '',
    decimal: '.',
    enableScrollSpy: true,
    scrollSpyRunOnce: true,
  };

  let idNumber = 1;

  counts.forEach((count) => {
    const id = `count-up-${idNumber}`,
        value = parseFloat(count.textContent),
        decimalPlaces = value % 1 !== 0 ? value.toString().split('.')[1].length : 0;

    let optionsFromDataAttr = { ...count.dataset };

    for (const key in optionsFromDataAttr) {
      if (optionsFromDataAttr[key] === '') {
        optionsFromDataAttr[key] = true;
      }
    }

    count.id = id;

    const countUpInstance = new CountUp(
        id,
        value,
        {
          ...defaultOptions,
          decimalPlaces: decimalPlaces,
          ...optionsFromDataAttr,
        }
    );

    if (!countUpInstance.error) {
      countUpInstance.start();
    } else {
      console.error(`Error initializing CountUp for ${id}:`, countUpInstance.error);
    }

    idNumber++;
  });
}
