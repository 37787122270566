import Aos from 'aos';

Aos.init({
	startEvent: 'load',
	offset: innerWidth > 640 ? 80 : 40,
	delay: 0,
	duration: 500,
	easing: "ease",
	once: true,
});


console.log(Aos);