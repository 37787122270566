import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

window.jobsSwiperInit = function jobsSwiperInit() {
  let jobsSwiperWr = document.querySelectorAll('.jobs-swiper-wr');
  jobsSwiperWr.forEach((el) => {
    if (el) {
      if(!el.classList.contains('is-init')) {
        el.classList.add('is-init');
        let parent = el.parentElement.parentElement;
        let swiperEl = el.querySelector('.swiper');
        let nextEl = parent.querySelector('.swiper-button-next');
        let prevEl = parent.querySelector('.swiper-button-prev');
        let slidesCount = el.querySelectorAll('.swiper-slide').length;
        let wrapper = swiperEl.querySelector('.swiper-wrapper');

        let swiper = new Swiper(swiperEl, {
          modules: [Autoplay, Navigation],
          observer: true, //needs to calculate swiper size
          observeParents: true, //needs to calculate swiper size
          spaceBetween: 20,
          slidesPerView: 1,
          threshold: 10,
          navigation: {
            nextEl: nextEl,
            prevEl: prevEl,
          },
          breakpoints: {
            641: {
              spaceBetween: 20,
              slidesPerView: 2,
            },
            768: {
              spaceBetween: 20,
              slidesPerView: 2.5,
            },
            1023: {
              spaceBetween: 20,
              slidesPerView: 3,
            },
            1281: {
              spaceBetween: 20,
              slidesPerView: 4,
            },
            1367: {
              spaceBetween: 30,
              slidesPerView: 4,
            },
          },
          autoplay: {
            delay: 4000,
            disableOnInteraction: false,
            pauseOnMouseEnter: window.innerWidth >= 640,
          },
        });
        swiperObserver(swiper);
      }
    }
  });
}

jobsSwiperInit();

/*
if (document.querySelector('.jobs-section__tabs')) {
  const jobTabsArr = document.querySelectorAll('.jobs-section__tab-btn');
  const jobContainerEl = document.querySelector('.jobs-section__container');
  const jobSlidersArr = document.querySelectorAll('.jobs-section__slider');

  jobTabsArr.forEach((tab, i) => {
    tab.addEventListener('click', (e) => {
      for (let j = 0; j < jobTabsArr.length; j++) {
        jobTabsArr[j].classList.remove('active');
        jobSlidersArr[j].classList.remove('active');
      }
      tab.classList.add('active');
      jobSlidersArr[i].classList.add('active');
    });
  });

  setTimeout(function () {
    jobContainerEl.style.minHeight = `${jobSlidersArr[0].clientHeight}px`;
  }, 200);
}
*/
