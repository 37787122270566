const contents = document.querySelectorAll('.sticky-content');


document.addEventListener("DOMContentLoaded", () => {
  const calcContentHeight = () => {
    if(contents.length) {
      contents.forEach((content) => {
        const height = +content.getAttribute('data-height');
    
        if(content.clientHeight > height) {
          content.parentElement.classList.add('sticky-active');
        } else {
          content.parentElement.classList.remove('sticky-active');
        }
      })
    }
  }

  calcContentHeight()
  window.addEventListener('resize', () => calcContentHeight())
})