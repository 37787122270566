const tabsComponentArr = document.querySelectorAll('.tabs-section');
tabsComponentArr.forEach((tabs) => {
  const tabButtonsArr = tabs.querySelectorAll('.tabs-section__tab-btn');
  const tabImagesArr = tabs.querySelectorAll('.tabs-section__image');

  tabButtonsArr.forEach((tab, idx) => {
    tab.addEventListener('click', () => {
      if (tab.classList.contains('active')) {
        tab.classList.remove('active');
        if(!tabs.classList.contains('type-3')) {
          tabImagesArr[idx].classList.remove('active');
        }
      } else {
        tabButtonsArr.forEach((t) => t.classList.remove('active'));
        if(!tabs.classList.contains('type-3')) {
          tabImagesArr.forEach((t) => t.classList.remove('active'));
          tabImagesArr[idx].classList.add('active');
        }
        tab.classList.add('active');
      }
    });
  });
});