window.plyrInit = function plyrInit() {
  const videoPlayer = document.getElementsByClassName('video-player')[0];

  if (videoPlayer) {
    const videoPlayersArr = document.querySelectorAll('.video-player');

    for (let i = 0; i < videoPlayersArr.length; i++) {

      if (!videoPlayersArr[i].classList.contains('is-init')) {
        videoPlayersArr[i].classList.add('is-init');

        const parentEl = videoPlayersArr[i].parentElement;
        let ratio = null;
        if (parentEl.classList.contains('first-text-section__video')) {
          if (window.innerWidth <= 640) {
            ratio = '335:200';
          } else if (window.innerWidth <= 951) {
            ratio = '1280:480';
          } else {
            ratio = '1039:584';
          }
        }
        const player = new Plyr(videoPlayersArr[i], {
          controls: [
            'play-large',
            'play',
            'progress',
            'current-time',
            'mute',
            'volume',
            'captions',
            'settings',
            'fullscreen',
            'pip',
            'airplay',
          ],
          ratio: ratio,
          tooltips: { controls: !0 },
          fullscreen: { iosNative: true },
        });

        player.on('enterfullscreen', function () {
          console.log('enterfullscreen');
        });

        player.on('exitfullscreen', function () {
          console.log('exitfullscreen');
        });

        player.on('play', function () {
          parentEl.classList.add('is-played');
        });

        player.on('pause', function () {
          parentEl.classList.remove('is-played');
        });

        // Expose player so it can be used from the console
        window.player = player;

        if (window.innerWidth >= 1024) {
          setTimeout(function () {
            const plyrArr = document.querySelectorAll('.plyr');
            for (let j = 0; j < plyrArr.length; j++) {
              plyrArr[i].classList.add('plyr-hide-controls');
            }

            plyrArr[i].addEventListener('mouseenter', () => {
              plyrArr[i].classList.remove('plyr-hide-controls');
            });

            plyrArr[i].addEventListener('mouseleave', () => {
              plyrArr[i].classList.add('plyr-hide-controls');
            });
          }, 300);
        }
      }
    }
  }
}

plyrInit();
