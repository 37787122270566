import Swiper, { Autoplay, Navigation } from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';

let employersSwiperWr = document.querySelectorAll('.employers-swiper-wr');
employersSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      modules: [Autoplay, Navigation],
      observer: true, //needs to calculate swiper size
      observeParents: true, //needs to calculate swiper size
      spaceBetween: 40,
      slidesPerView: 2,
      threshold: 10,
      navigation: {
        nextEl: nextEl,
        prevEl: prevEl,
      },
      breakpoints: {
        552: {
          spaceBetween: 30,
          slidesPerView: 3,
        },
        768: {
          spaceBetween: 30,
          slidesPerView: 4,
        },
        1023: {
          spaceBetween: 30,
          slidesPerView: 4,
        },
        1200: {
          spaceBetween: 30,
          slidesPerView: 5,
        },
        1281: {
          spaceBetween: 40,
          slidesPerView: 6,
        },
        1367: {
          spaceBetween: 70,
          slidesPerView: 6,
        },
      },
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
        pauseOnMouseEnter: window.innerWidth >= 640,
      },
    });
    swiperObserver(swiper);

    /*if(window.innerWidth > 552 && slidesCount <= 2) {
      nextEl.style.display = 'none';
      prevEl.style.display = 'none';
    } else if (window.innerWidth > 768 && slidesCount <= 3) {
      nextEl.style.display = 'none';
      prevEl.style.display = 'none';
    } else if (window.innerWidth > 1023 && slidesCount <= 4) {
      nextEl.style.display = 'none';
      prevEl.style.display = 'none';
    } else if (window.innerWidth > 1200 && slidesCount <= 5) {
      nextEl.style.display = 'none';
      prevEl.style.display = 'none';
    }*/
  }
});
