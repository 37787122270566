import Swiper from 'swiper';
import { swiperObserver } from '../utils/swiper.utils.js';
import { marqueeSwiperFunction } from '../utils/swiperMarquee.utils.js';

let partnersSwiperWr = document.querySelectorAll('.partners-swiper-wr');
partnersSwiperWr.forEach((el) => {
  if (el) {
    let swiperEl = el.querySelector('.swiper');
    let nextEl = el.querySelector('.swiper-button-next');
    let prevEl = el.querySelector('.swiper-button-prev');
    let slidesCount = el.querySelectorAll('.swiper-slide').length;
    let wrapper = swiperEl.querySelector('.swiper-wrapper');
    let swiper = new Swiper(swiperEl, {
      observer: true,
      observeParents: true,
      spaceBetween: 20,
      slidesPerView: 1.4,
      threshold: 10,
      freeMode: true,
      loop: slidesCount > 5,
      loopedSlides: slidesCount > 5 ? slidesCount * 2 : false,

      breakpoints: {
        552: {
          spaceBetween: 16,
          slidesPerView: 2,
        },
        768: {
          spaceBetween: 50,
          slidesPerView: 4,
        },
        1024: {
          spaceBetween: 110,
          slidesPerView: 4,
        },
      },
    });

    marqueeSwiperFunction(swiper, {
      speed: 0.77,
    });
  }
});
